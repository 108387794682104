// src/hooks/useFetchGameData.js
import { useState, useCallback, useEffect, useRef } from "react";
import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";

/**
 * Custom hook to fetch and manage game data from the Blackjack smart contract.
 *
 * @param {string} address - The player's blockchain address.
 * @param {ethers.Provider} provider - The ethers.js provider (local or wallet).
 * @param {function} addAlert - Function to display alerts to the user.
 * @param {string} blackjackAddress - The address of the Blackjack contract.
 * @returns {object} - An object containing game data and related functions.
 */
const useFetchGameData = (address, provider, addAlert, blackjackAddress) => {
  // State to hold the game data fetched from the contract
  const [gameData, setGameData] = useState({
    playerHands: [[]],
    dealerHand: [],
    betAmounts: [],
    handStatuses: [],
    handReasons: [],
    payouts: [],
    gameStarted: false,
    gameSettled: false,
    currentHandIndex: 0,
    maxBet: "0",
    totalEscrow: "0",
  });

  // State to manage loading status
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Formats a card object from the contract into a human-readable string.
   *
   * @param {object} card - The card object with `rank` and `suit`.
   * @returns {string} - The formatted card string (e.g., "A♠") or "??" if unknown.
   */
  const formatCard = (card) => {
    const rankMapping = [
      "A",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "J",
      "Q",
      "K",
    ];
    const suitMapping = ["♠", "♥", "♦", "♣"];

    const rankNumber = Number(card.rank);
    const suitNumber = Number(card.suit);

    // If rankNumber is 0, the card is face-down or unknown
    if (rankNumber === 0) {
      return "??";
    }

    const rank = rankMapping[rankNumber - 1] || "??";
    const suit = suitMapping[suitNumber] || "??";

    return `${rank}${suit}`;
  };

  // useRef to prevent multiple simultaneous fetches
  const isUpdatingRef = useRef(false);

  /**
   * Fetches the current game data from the Blackjack smart contract.
   */
  const fetchGameData = useCallback(async () => {
    // Prevent fetching if already updating or if address/provider is missing
    if (isUpdatingRef.current || !address || !provider || !blackjackAddress)
      return;
    isUpdatingRef.current = true;
    setIsLoading(true);

    try {
      // Initialize the contract instance with the provided provider
      const contract = new ethers.Contract(
        blackjackAddress,
        BlackjackABI,
        provider
      );

      // Fetch the game status for the player
      const { game, totalEscrowValue } = await contract.gameStatus(address);

      // Format player hands
      const playerHands = game.playerHands?.length
        ? game.playerHands.map((handStruct) => handStruct.cards.map(formatCard))
        : [[]];

      // Format dealer hand
      let dealerHand = [];
      if (game.dealerHand.cards?.length) {
        dealerHand = game.dealerHand.cards.map(formatCard);
      }

      // Format bet amounts and payouts (convert from wei to POL)
      const betAmounts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.bet)) || [];
      const payouts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.payout)) || [];

      // Map result enums to readable strings
      const resultMapping = [
        "NotSet",
        "BLACKJACK!",
        "Push",
        "Player Won",
        "Dealer Won",
        "Busted",
      ];
      const handStatuses =
        game.playerHands?.map(
          (hand) => resultMapping[hand.result] || "Unknown"
        ) || [];
      const handReasons =
        game.playerHands?.map((hand) => (hand.reason ? hand.reason : "")) || [];

      // Fetch totalEscrow and contract balance (both as BigInt)
      const totalEscrow = totalEscrowValue; // BigInt from contract
      const contractBalance = await provider.getBalance(blackjackAddress); // BigInt

      // Fetch the player's balance
      const playerBalance = await provider.getBalance(address); // BigInt

      // Calculate maxBet: (contractBalance - totalEscrow) / 10n
      let maxBet = 0n;
      if (contractBalance >= totalEscrow) {
        maxBet = (contractBalance - totalEscrow) / 10n; // Use BigInt division
      }

      // Ensure maxBet doesn't exceed player's balance
      if (playerBalance < maxBet) {
        maxBet = playerBalance;
      }

      // Convert maxBet and totalEscrow from wei (BigInt) to POL (number with 3 decimals)
      const maxBetEther = parseFloat(
        parseFloat(ethers.formatEther(maxBet)).toFixed(3)
      );
      const totalEscrowEther = parseFloat(
        parseFloat(ethers.formatEther(totalEscrow)).toFixed(3)
      );

      // Assemble the new game data object
      const newGameData = {
        playerHands,
        dealerHand,
        betAmounts,
        payouts,
        handStatuses,
        handReasons,
        gameStarted: playerHands.some((hand) =>
          hand.some((card) => card !== "??")
        ),
        gameSettled: game.settled,
        currentHandIndex: Number(game.currentHandIndex),
        maxBet: maxBetEther.toString(),
        totalEscrow: totalEscrowEther.toString(),
      };

      // Update the state with the fetched data
      setGameData(newGameData);
    } catch (err) {
      // Handle errors by showing an alert and resetting gameStarted
      addAlert({
        status: "error",
        title: "Error Fetching Game Data",
        description: `Error fetching game data: ${err.message}`,
      });
      console.error("Error fetching game data:", err);
      setGameData((prev) => ({ ...prev, gameStarted: false }));
    } finally {
      isUpdatingRef.current = false;
      setIsLoading(false);
    }
  }, [address, provider, addAlert, blackjackAddress]);

  /**
   * Fetch game data whenever the `address`, `provider`, or `blackjackAddress` changes.
   */
  useEffect(() => {
    fetchGameData();
  }, [fetchGameData]);

  return { gameData, setGameData, fetchGameData, isLoading, setIsLoading };
};

export default useFetchGameData;
