import React from "react";
import { Heading, Image, Box } from "@chakra-ui/react";

const Header = ({ isStandalone }) => {
  return (
    <Box
      pt={isStandalone ? 10 : 0}
      backgroundColor="rgba(162, 181, 197, 0.5)"
      align="center"
      color="#1f3c60"
    >
      <Box align="left" width="24px" pt={3}>
        <Image src="/logo.svg" />
      </Box>
      <Heading size="xl" p={3}>
        Polygon Blackjack
      </Heading>
    </Box>
  );
};

export default Header;
