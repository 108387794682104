import React from "react";
import {
  Box,
  Link,
  Text,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ListItem,
  Divider,
  Heading,
  List,
  ListIcon,
} from "@chakra-ui/react";
import { ExternalLinkIcon, TimeIcon } from "@chakra-ui/icons";
import {
  FaLayerGroup,
  FaArrowsAltH,
  FaAngleDoubleUp,
  FaAngleDoubleDown,
  FaUserTie,
  FaDollarSign,
  FaChartLine,
  FaEthereum,
  FaCode,
  FaFlask,
} from "react-icons/fa";

const Instructions = () => {
  return (
    <Box color="#1f3c60" maxW="600px" minW="40px">
      <Accordion allowMultiple w="100%">
        {/* Main Accordion Item: What is Polygon Blackjack? */}
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="center">
              <Heading size="sm" color="#1f3c60">
                What is Polygon Blackjack?
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {/* Content inside the main accordion */}
            <Heading size="md" mb={3} align="center" color="#1f3c60">
              Blackjack on the Polygon Blockchain
            </Heading>

            <Box mb={3} align="center">
              <List display="flex" justifyContent="center" alignItems="center">
                <ListItem display="flex" alignItems="center" mr={4}>
                  <ListIcon as={FaAngleDoubleUp} color="blue.500" />
                  <Text color="blue.500" fontWeight="bold">
                    Transparent
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center" mr={4}>
                  <ListIcon as={FaUserTie} color="purple.500" />
                  <Text color="purple.500" fontWeight="bold">
                    Player-Friendly
                  </Text>
                </ListItem>
                <ListItem display="flex" alignItems="center">
                  <ListIcon as={FaChartLine} color="green.500" />
                  <Text color="green.500" fontWeight="bold">
                    Dynamic
                  </Text>
                </ListItem>
              </List>
            </Box>

            <Divider my={3} />

            {/* Combined Content */}
            <List spacing={3}>
              {/* Gameplay */}
              <ListItem>
                <ListIcon as={FaLayerGroup} color="blue.500" />
                <b>Single Deck</b> One deck shuffled every hand.
              </ListItem>
              <ListItem>
                <ListIcon as={FaArrowsAltH} color="blue.500" />
                <b>Split any pairs</b> Including aces, as many times as you'd
                like.
              </ListItem>
              <ListItem>
                <ListIcon as={FaAngleDoubleDown} color="blue.500" />
                <b>Double down on 9, 10, or 11</b> Even after splitting.
              </ListItem>
              <ListItem>
                <ListIcon as={FaUserTie} color="purple.500" />
                <b>Soft 17</b> Dealer must hit on soft 17.
              </ListItem>
              <ListItem>
                <ListIcon as={FaDollarSign} color="purple.500" />
                <b>Blackjack pays 3:2</b> Natural blackjack only, not after
                splitting.
              </ListItem>
              <ListItem>
                <ListIcon as={FaEthereum} color="purple.500" />
                <b>No Bank</b> Hands are played directly against the{" "}
                <Link
                  color="blue.500"
                  href="https://soliditylang.org/"
                  isExternal
                >
                  Solidity <ExternalLinkIcon />
                </Link>{" "}
                smart contract.
              </ListItem>

              {/* Dynamic Limits */}
              <ListItem>
                <ListIcon as={FaChartLine} color="green.500" />
                <b>Dynamic Limits</b> A bet may be at most one-tenth of the
                dealer's bankroll. When a bet is placed, 8 times the bet amount
                is reserved to ensure payouts.
              </ListItem>

              {/* Fairness and Transparency */}

              {1 == 2 && (
                <ListItem>
                  <ListIcon as={FaCode} color="green.500" />
                  <b>Open Source</b> View the smart contract code on{" "}
                  <Link
                    color="blue.500"
                    href="https://polygonscan.com/"
                    isExternal
                  >
                    PolygonScan <ExternalLinkIcon />
                  </Link>{" "}
                  (coming soon).
                </ListItem>
              )}

              {/* Important Notes */}
              <ListItem>
                <ListIcon as={TimeIcon} color="green.500" />
                <b>Inactivity</b> Games unresolved after 1 hour are subject to
                automatically being settled in favor of the dealer.
              </ListItem>
              <ListItem>
                <ListIcon as={FaFlask} color="green.500" />
                <b>Free</b> Practice using one of 20 free accounts or connect a
                wallet to play on the{" "}
                <Link
                  color="blue.500"
                  href="https://polygon.technology/blog/introducing-the-amoy-testnet-for-polygon-pos"
                  isExternal
                >
                  Polygon Amoy testnet <ExternalLinkIcon />
                </Link>
                .
              </ListItem>
            </List>
            <Accordion allowToggle pt={5} w="100%">
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="center">
                    <Heading size="sm" color="#1f3c60">
                      How does it work?
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Text pb={1}>
                    The game state is stored on the Polygon blockchain, with
                    each action being a transaction triggering game progression.
                  </Text>
                  <Text>
                    You can play in Practice Mode or on the Polygon Amoy
                    testnet.
                  </Text>
                  <Heading size="sm" pt={5} pb={1}>
                    Practice Mode
                  </Heading>
                  <Text>
                    If you haven't connected a wallet, this is what you are
                    playing. The game state is stored directly on a hosted
                    machine, which may be reset at any time.
                  </Text>
                  <Heading size="sm" pt={5} pb={1}>
                    Polygon Amoy Testnet
                  </Heading>
                  <Text pb={1}>
                    If you connect a wallet over the Polygon Amoy Testnet, you
                    can play using your own funds.
                  </Text>
                  <Text>
                    To set up your wallet on the Polygon Amoy Testnet, follow{" "}
                    <Link
                      color="blue.500"
                      href="https://polygon.technology/blog/introducing-the-amoy-testnet-for-polygon-pos"
                      isExternal
                    >
                      these instructions <ExternalLinkIcon />
                    </Link>
                    .
                  </Text>

                  <Heading
                    fontWeight="bold"
                    textAlign="center"
                    pt={3}
                    size="lg"
                  >
                    Good luck!
                  </Heading>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Instructions;
