import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import { testAccounts } from "../testAccounts";

const PlayerAccount = ({
  currentAccount,
  onAccountChange,
  playerBalance,
  isDisabled,
}) => {
  const { isConnected } = useAccount();
  const handleAccountChange = (selectedAddress) => {
    onAccountChange(selectedAddress);
  };

  return (
    <HStack p="3">
      {!isConnected && (
        <Menu>
          <Tooltip
            label="Switch between test accounts"
            aria-label="Select Account Tooltip"
          >
            <MenuButton
              as={Button}
              backgroundColor="#35654d"
              color="#F9F6EE"
              fontSize="16px"
              borderRadius="10px"
              border="1px solid #F9F6EE"
              boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
              _hover={{
                backgroundColor: "#2f5a46",
                cursor: "pointer",
              }}
              disabled={isDisabled}
            >
              {testAccounts.find(
                (account) => account.address === currentAccount
              )?.label || "Select Account"}
              {": "}
              {parseFloat(playerBalance || 0).toFixed(3)}
            </MenuButton>
          </Tooltip>

          <MenuList
            bg="#35654d"
            border="1px solid #F9F6EE"
            zIndex="10"
            maxH="300px"
            overflowY="auto"
          >
            {testAccounts.length > 0 ? (
              testAccounts.map((account) => (
                <MenuItem
                  key={account.address}
                  onClick={() => handleAccountChange(account.address)}
                  _hover={{ bg: "#2f5a46", color: "#F9F6EE" }}
                  bg="#35654d"
                  color="#F9F6EE"
                >
                  {account.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem isDisabled>No accounts found</MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      <ConnectKitButton.Custom>
        {({ isConnected, show }) => {
          if (isConnected) {
            // When connected, use the standard ConnectKitButton with additional info
            return (
              <Tooltip
                label="Disconnect to play for free"
                aria-label="Account Details Tooltip"
              >
                {/* Wrap ConnectKitButton with Box to forward the ref */}
                <Box as="span">
                  <ConnectKitButton
                    showAvatar={true}
                    showBalance={true}
                    showNetwork={true}
                    onClick={show}
                  />
                </Box>
              </Tooltip>
            );
          } else {
            // When disconnected, use your custom styled button
            return (
              <Tooltip
                label="Connect your wallet to use your own account"
                aria-label="Connect Wallet Tooltip"
              >
                <Button
                  onClick={show}
                  backgroundColor="#35654d"
                  color="#F9F6EE"
                  fontSize="16px"
                  borderRadius="10px"
                  border="1px solid #F9F6EE"
                  boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
                  isDisabled={isDisabled}
                  _hover={{
                    backgroundColor: "#2f5a46",
                    cursor: "pointer",
                  }}
                >
                  Connect Wallet
                </Button>
              </Tooltip>
            );
          }
        }}
      </ConnectKitButton.Custom>
    </HStack>
  );
};

export default PlayerAccount;
