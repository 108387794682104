// src/utils/calculateHandValues.js
const calculateHandValues = (hand) => {
  let total = 0;
  let aces = 0;

  hand.forEach((cardStr) => {
    const cardValue = cardStr.slice(0, -1);
    let value = 0;

    if (cardValue === "A") {
      aces += 1;
      value = 11;
    } else if (["K", "Q", "J"].includes(cardValue)) {
      value = 10;
    } else if (cardValue === "??") {
      value = 0;
    } else {
      value = parseInt(cardValue);
    }

    total += value;
  });

  const possibleTotals = [];
  for (let i = 0; i <= aces; i++) {
    let adjustedTotal = total - i * 10;
    possibleTotals.push(adjustedTotal);
  }

  return [...new Set(possibleTotals)];
};

export default calculateHandValues;
