import { useCallback } from "react";

const delay = 250;

const useCardAnimation = (
  gameData,
  cardShown,
  setAnimatedPlayerHands,
  setAnimatedDealerHand,
  setCardShown,
  setLastFlippedCardIndex
) => {
  const flipSingleHand = useCallback(
    async (handType, handIndex, handData, setAnimatedHands) => {
      console.log(`${handType} hand ${handIndex}. ${handData}`);
      for (let cardIndex = 0; cardIndex < handData.length; cardIndex++) {
        const cardShownFlag =
          handType === "player"
            ? cardShown.player[handIndex]?.[cardIndex]
            : cardShown.dealer[cardIndex];
        const cardValue = handData[cardIndex];

        if (!cardShownFlag && cardValue !== "??") {
          setAnimatedHands((prevHands) => {
            const updatedHands = [...prevHands];
            if (handType === "player") {
              if (!updatedHands[handIndex]) {
                updatedHands[handIndex] = [];
              }
              updatedHands[handIndex][cardIndex] = "??";
            } else {
              if (!updatedHands[cardIndex]) {
                updatedHands[cardIndex] = "??";
              }
            }
            return updatedHands;
          });

          await new Promise((resolve) => setTimeout(resolve, delay));

          setAnimatedHands((prevHands) => {
            const updatedHands = [...prevHands];
            if (handType === "player") {
              updatedHands[handIndex][cardIndex] = cardValue;
            } else {
              updatedHands[cardIndex] = cardValue;
            }
            return updatedHands;
          });

          await new Promise((resolve) => setTimeout(resolve, delay));

          setCardShown((prevCardShown) => {
            const updatedCardShown = { ...prevCardShown };
            if (handType === "player") {
              if (!updatedCardShown.player[handIndex]) {
                updatedCardShown.player[handIndex] = [];
              }
              updatedCardShown.player[handIndex][cardIndex] = true;
            } else {
              if (!updatedCardShown.dealer) {
                updatedCardShown.dealer = [];
              }
              updatedCardShown.dealer[cardIndex] = true;
            }
            return updatedCardShown;
          });

          setLastFlippedCardIndex((prevLastFlippedCardIndex) => {
            const updatedLastFlippedCardIndex = { ...prevLastFlippedCardIndex };
            if (handType === "player") {
              updatedLastFlippedCardIndex[handIndex] = cardIndex;
            } else {
              updatedLastFlippedCardIndex.dealer = cardIndex;
            }
            return updatedLastFlippedCardIndex;
          });

          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          // console.log(`Skipping ${handType} hand ${handIndex !== null ? handIndex : "dealer"}, card ${cardIndex}: cardShownFlag = ${cardShownFlag}, cardValue = ${cardValue}`);
        }
      }
    },
    [cardShown, setCardShown, setLastFlippedCardIndex]
  );

  const flipHandsSequentially = useCallback(
    async (handType) => {
      if (handType === "player") {
        for (
          let handIndex = 0;
          handIndex < gameData.playerHands.length;
          handIndex++
        ) {
          const playerHand = gameData.playerHands[handIndex];
          await flipSingleHand(
            handType,
            handIndex,
            playerHand,
            setAnimatedPlayerHands
          );
        }
      } else if (handType === "dealer") {
        const dealerHand = gameData.dealerHand;
        await flipSingleHand(handType, null, dealerHand, setAnimatedDealerHand);
      }
    },
    [
      gameData.playerHands,
      gameData.dealerHand,
      flipSingleHand,
      setAnimatedPlayerHands,
      setAnimatedDealerHand,
    ]
  );

  return { flipHandsSequentially };
};

export default useCardAnimation;
