// src/components/Betting.js
import React from "react";
import {
  HStack,
  Input,
  Button,
  Tooltip,
  Switch,
  Text,
  InputGroup,
  InputLeftAddon,
  Box,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  80% { transform: scale(1); }
  90% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const Betting = ({
  isLoading,
  betAmount,
  handleBetAmountChange,
  startNewGame,
  maxBet,
  betMaxEnabled,
  setBetMaxEnabled,
  chainId,
}) => {
  // Parse betAmount to float for validation
  const numericBet = parseFloat(betAmount);
  const isBetAmountValid =
    !isNaN(numericBet) && numericBet >= 0.001 && numericBet <= maxBet;

  // Tooltip message for invalid input
  const tooltipMessage =
    !isBetAmountValid && betAmount !== ""
      ? `Bet must be between 0.001 and ${parseFloat(maxBet)}`
      : "";

  // Handle click on the Bet Max box
  const toggleBetMax = () => {
    if (!isLoading) {
      const newBetMaxEnabled = !betMaxEnabled;
      setBetMaxEnabled(newBetMaxEnabled);
      if (newBetMaxEnabled) {
        handleBetAmountChange(maxBet.toString());
      } else {
        handleBetAmountChange("");
      }
    }
  };

  return (
    !isLoading && (
      <HStack
        align="center"
        spacing={3}
        p={4}
        bg="#2f5a46"
        borderRadius="10px"
        border="1px solid whitesmoke"
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
      >
        {/* Bet Amount Input with POL on the left */}
        <Box width="150px">
          <Tooltip
            label={tooltipMessage}
            isOpen={!isBetAmountValid && betAmount !== ""}
            hasArrow
            bg="red.600"
            color="whitesmoke"
            placement="top"
          >
            <InputGroup>
              <InputLeftAddon
                children={
                  chainId == "80002"
                    ? "Amoy"
                    : chainId == "1337"
                    ? "Local"
                    : "POL"
                }
                bg="#35654d"
                color="whitesmoke"
                border="1px solid whitesmoke"
                borderRadius="10px 0 0 10px"
              />
              <Input
                type="text"
                value={betAmount}
                variant="outline"
                onChange={(e) => handleBetAmountChange(e.target.value)}
                bg="#35654d"
                color="whitesmoke"
                _hover={{ borderColor: "whitesmoke" }}
                _focus={{ borderColor: "whitesmoke" }}
                default="0.001"
                borderRadius="0 10px 10px 0"
              />
            </InputGroup>
          </Tooltip>
        </Box>

        {/* Bet Button */}
        <Button
          onClick={startNewGame}
          size="md"
          width="60px"
          bg="#35654d"
          color="whitesmoke"
          border="1px solid whitesmoke"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          _active={{ backgroundColor: "#254c3a" }}
          isDisabled={!isBetAmountValid || isLoading}
          animation={`${pulseAnimation} 5s infinite`}
        >
          Bet
        </Button>

        {/* Bet Max Toggle with Clickable Box */}
        <HStack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg="#35654d"
          border="1px solid whitesmoke"
          borderRadius="10px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
          cursor="pointer"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          onClick={toggleBetMax}
          h="40px" // Match height of other components
          pl="10px"
          pr="10px"
        >
          <Switch
            isChecked={betMaxEnabled}
            onChange={() => {}}
            colorScheme="green"
            size="sm"
            isDisabled={isLoading}
            pointerEvents="none"
          />
          <Text fontSize="md" color="whitesmoke" wordBreak="keep-all">
            Bet&nbsp;Max
          </Text>
        </HStack>
      </HStack>
    )
  );
};

export default Betting;
