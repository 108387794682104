import { useState, useEffect } from "react";

const getSafeAreaInsets = () => ({
  top: parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "env(safe-area-inset-top)"
    ) || "0",
    10
  ),
  right: parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "env(safe-area-inset-right)"
    ) || "0",
    10
  ),
  bottom: parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "env(safe-area-inset-bottom)"
    ) || "0",
    10
  ),
  left: parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "env(safe-area-inset-left)"
    ) || "0",
    10
  ),
});

export const useSafeAreaInsets = () => {
  const [insets, setInsets] = useState(getSafeAreaInsets());

  useEffect(() => {
    const handleResize = () => {
      const updatedInsets = getSafeAreaInsets();
      setInsets(updatedInsets);
    };

    // Initial update
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return insets;
};
