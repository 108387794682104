// src/utils/ethersUtils.js
import { ethers, Wallet } from "ethers";
import BlackjackABI from "../BlackjackABI.json";
import { testAccounts } from "../testAccounts";

/**
 * Creates and returns a contract instance.
 *
 * @param {Signer} signer - The signer to connect with the contract.
 * @param {string} blackjackAddress - The address of the Blackjack contract.
 * @returns {Contract} - The contract instance.
 */
export const getContract = (signer, blackjackAddress) => {
  if (!signer) {
    throw new Error(
      "Signer is not defined. Ensure you are passing a valid signer."
    );
  }
  return new ethers.Contract(blackjackAddress, BlackjackABI, signer);
};

/**
 * Retrieves the signer based on the network mode.
 *
 * @param {string} address - The Ethereum address of the player.
 * @param {ethers.providers.Provider} provider - The ethers provider.
 * @param {boolean} isLocal - Flag indicating if the network is local.
 * @returns {Wallet | Signer} - The appropriate signer.
 */
export const getSigner = (address, provider, isLocal) => {
  if (isLocal) {
    // Local Test Accounts
    const account = testAccounts.find(
      (acc) => acc.address.toLowerCase() === address.toLowerCase()
    );
    if (!account) {
      throw new Error(`Account with address ${address} not found.`);
    }
    const privateKey = account.privateKey;
    if (!privateKey) {
      throw new Error(`Private key for address ${address} not found.`);
    }
    return new Wallet(privateKey, provider); // Local signer
  } else {
    // Connected wallet
    if (typeof provider.getSigner === "function") {
      return provider.getSigner(); // Synchronous in ethers.js v6
    } else {
      throw new Error("Provider does not support getSigner");
    }
  }
};

/**
 * Executes a contract function using the appropriate signer.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Signer} params.signer - The ethers signer.
 * @param {string} params.blackjackAddress - The address of the Blackjack contract.
 * @param {Function} params.setIsLoading - Function to set loading state.
 * @param {Function} params.fetchGameData - Function to fetch game data.
 * @param {Function} params.addAlert - Function to add alerts.
 * @param {Function} params.updateBalances - Function to update balances.
 * @param {string} params.functionName - The contract function name to execute.
 * @param {Array} params.functionArgs - Arguments for the contract function.
 * @param {string|null} params.value - The value to send with the transaction.
 * @param {Function} params.setLoadingAction - Function to set loading action.
 * @param {number} [params.maxRetries=5] - Maximum number of retry attempts.
 * @returns {Promise<void>}
 */
export const executeContractFunction = async ({
  signer,
  blackjackAddress,
  setIsLoading,
  fetchGameData,
  addAlert,
  updateBalances,
  functionName,
  functionArgs = [],
  value = null,
  setLoadingAction,
  maxRetries = 5,
}) => {
  setIsLoading(true);
  let attempts = 0;
  let success = false;
  let lastError = null;

  if (!signer) {
    console.error("Signer is not defined.");
    addAlert({
      status: "error",
      title: "Signer Not Available",
      description: "Signer is not available for transaction execution.",
    });
    setIsLoading(false);
    return;
  }

  const contract = getContract(signer, blackjackAddress);

  while (attempts < maxRetries && !success) {
    try {
      attempts += 1;

      const tx = value
        ? await contract[functionName](...functionArgs, { value })
        : await contract[functionName](...functionArgs);

      await tx.wait();

      await fetchGameData();
      await updateBalances();
      success = true;
    } catch (err) {
      lastError = err;
      console.error(`Attempt ${attempts} failed:`, err);
      if (err.code === "INSUFFICIENT_FUNDS" || err.code === "CALL_EXCEPTION") {
        break; // Exit retry loop if funds are insufficient or call fails
      }
      const delay = Math.pow(2, attempts) * 1000;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  if (!success) {
    const reasonMatch = lastError?.message.match(/reason="([^"]+)"/);
    const reason = reasonMatch ? reasonMatch[1] : "An unknown error occurred";

    addAlert({
      status: "error",
      title: `Error during ${functionName}`,
      description: `Reason: ${reason}`,
    });

    console.error(`All ${attempts} attempts failed.`);
    console.error(lastError);
  }

  setLoadingAction("");
  setIsLoading(false);
};
