import React, { useState, useMemo } from "react";
import { VStack, Box, useColorModeValue } from "@chakra-ui/react";
import { useSafeAreaInsets } from "./hooks/useSafeAreaInsets";
import Header from "./Header";
import { useAlerts } from "./Alerts";
import Instructions from "./Instructions";
import Blackjack from "./Blackjack";

function App() {
  const { AlertsComponent, addAlert } = useAlerts();
  const [isBlackjackFullScreen, setIsBlackjackFullScreen] = useState(false);

  // Detect if in standalone mode
  const isStandalone = useMemo(() => {
    if (typeof window === "undefined") return false;
    return (
      (window.navigator &&
        "standalone" in window.navigator &&
        window.navigator.standalone) ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  }, []);

  // If in standalone mode, use 'vh'; otherwise use 'dvh'
  const heightUnit = isStandalone ? "vh" : "dvh";

  const backgroundColor = useColorModeValue(
    isBlackjackFullScreen ? "green.700" : "gray.100",
    isBlackjackFullScreen ? "green.800" : "gray.900"
  );

  const toggleFullscreen = () => {
    setIsBlackjackFullScreen((prev) => !prev);
  };

  return (
    <Box
      minH={`100${heightUnit}`}
      minW="100vw"
      bg={backgroundColor}
      overflowX="auto"
      role="application"
      aria-label="Blackjack"
    >
      {!isBlackjackFullScreen && <Header isStandalone={isStandalone} />}
      <VStack spacing={0}>
        {!isBlackjackFullScreen && <Instructions />}
        {!isBlackjackFullScreen && <AlertsComponent />}
        <Blackjack
          addAlert={addAlert}
          fullscreen={isBlackjackFullScreen}
          toggleFullscreen={toggleFullscreen}
          isStandalone={isStandalone}
        />
      </VStack>
    </Box>
  );
}

export default App;
