// src/components/DealerHand.js
import React from "react";
import { Center } from "@chakra-ui/react";
import Hand from "./Hand";

const DealerHand = ({
  animatedDealerHand,
  colorScheme,
  gameStarted,
  ethAmount,
  gameLoaded,
  escrowAmount,
}) => {
  return (
    <Center h="100%">
      <Hand
        label="dealer"
        cards={animatedDealerHand}
        colorScheme={colorScheme}
        gameStarted={gameStarted}
        ethAmount={ethAmount}
        gameLoaded={gameLoaded}
        escrowAmount={escrowAmount}
      />
    </Center>
  );
};

export default DealerHand;
