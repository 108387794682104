// src/hooks/useColorScheme.js
import { useState, useCallback } from "react";

const useColorScheme = () => {
  const getAlternateColorScheme = (currentScheme) => {
    return currentScheme === "blue" ? "red" : "blue";
  };

  const [colorScheme, setColorScheme] = useState(() => {
    const storedScheme = localStorage.getItem("colorScheme");
    if (storedScheme) {
      return storedScheme;
    } else {
      const initialScheme = "blue";
      localStorage.setItem("colorScheme", initialScheme);
      return initialScheme;
    }
  });

  const setNewColorScheme = useCallback(() => {
    const currentScheme = localStorage.getItem("colorScheme") || "blue";
    const newColorScheme = getAlternateColorScheme(currentScheme);
    setColorScheme(newColorScheme);
    localStorage.setItem("colorScheme", newColorScheme);
  }, []);

  return [colorScheme, setNewColorScheme];
};

export default useColorScheme;
