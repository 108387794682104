import React, { useState, useCallback } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  CloseButton,
  Box,
  Flex,
} from "@chakra-ui/react";

export function useAlerts() {
  const [alerts, setAlerts] = useState([]);

  const addAlert = useCallback((newAlert) => {
    setAlerts((prevAlerts) => {
      const alertExists = prevAlerts.some(
        (alert) =>
          alert.title === newAlert.title &&
          alert.description === newAlert.description
      );
      return alertExists ? prevAlerts : [...prevAlerts, newAlert];
    });
  }, []);

  const removeAlert = useCallback((index) => {
    setAlerts((prevAlerts) => prevAlerts.filter((_, i) => i !== index));
  }, []);

  const AlertsComponent = () => (
    <Stack spacing={3} maxW="97%">
      {alerts.map((alert, index) => (
        <Alert status={alert.status} key={index}>
          <Flex align="center" justify="space-between">
            <Flex align="center">
              <AlertIcon />
              <Box>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.description && (
                  <AlertDescription>{alert.description}</AlertDescription>
                )}
              </Box>
            </Flex>
            <CloseButton
              onClick={() => removeAlert(index)}
              alignSelf="flex-start"
              marginTop="-3"
              marginRight="-4"
            />
          </Flex>
        </Alert>
      ))}
    </Stack>
  );

  return { AlertsComponent, addAlert, removeAlert };
}
