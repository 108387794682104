// src/Blackjack.js
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { VStack, Box, IconButton } from "@chakra-ui/react";
import { ethers, BrowserProvider, Wallet } from "ethers";
import { useAccount, useChainId, useWalletClient } from "wagmi";
import { FaCompress, FaExpand } from "react-icons/fa";
import Confetti from "react-confetti";

import { testAccounts } from "./testAccounts";
import useColorScheme from "./hooks/useColorScheme";
import useFetchGameData from "./hooks/useFetchGameData";
import useCardAnimation from "./hooks/useCardAnimation";
import { useSafeAreaInsets } from "./hooks/useSafeAreaInsets";
import { executeContractFunction } from "./utils/ethersUtils";
import calculateHandValues from "./utils/calculateHandValues";
import DealerHand from "./components/DealerHand";
import PlayerHands from "./components/PlayerHands";
import Betting from "./components/Betting";
import PlayerAccount from "./components/PlayerAccount";
import WelcomeScreen from "./components/WelcomeScreen";

const Blackjack = ({
  addAlert,
  fullscreen,
  toggleFullscreen,
  isStandalone,
}) => {
  // Hooks to get wallet and connection details
  const { address: connectedAddress, isConnected } = useAccount();
  const { data: walletClient } = useWalletClient();
  const chainIdFromHook = useChainId();
  const insets = useSafeAreaInsets();

  // State to manage the effective chain ID
  const [effectiveChainId, setEffectiveChainId] = useState(chainIdFromHook);

  // Refs for managing updates
  const isUpdatingRef = useRef(false);
  const needsUpdateRef = useRef(false);

  // State declarations
  const [playerAddress, setPlayerAddress] = useState(() => {
    const stored = localStorage.getItem("playerAddress");
    return stored || null;
  });
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [dealerBankroll, setDealerBankroll] = useState("0.000");
  const [playerBankroll, setPlayerBankroll] = useState("0.000");
  const [showLabel, setShowLabel] = useState(false);
  const [showGameActions, setShowGameActions] = useState(false);
  const [showBetButton, setShowBetButton] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(false);
  const [betAmount, setBetAmount] = useState(() =>
    parseFloat(localStorage.getItem("betAmount") || "1")
  );
  const [loadingAction, setLoadingAction] = useState("");
  const [showGameStatus, setShowGameStatus] = useState(false);
  const [showPayoutMessage, setShowPayoutMessage] = useState(false);

  // State for Bet Max toggle
  const [betMaxEnabled, setBetMaxEnabled] = useState(false);

  // State to indicate the connection status
  const [connectionStatus, setConnectionStatus] = useState(null);

  // State to indicate whether the connection is initialized
  const [isConnectionInitialized, setIsConnectionInitialized] = useState(false);

  // Define blackjackAddress based on connection status and chain ID
  const blackjackAddress = useMemo(() => {
    if (!isConnectionInitialized || !connectionStatus) return null;

    if (connectionStatus === "amoy") {
      return process.env.REACT_APP_CONTRACT_ADDRESS_AMOY;
    } else if (connectionStatus === "local") {
      return process.env.REACT_APP_CONTRACT_ADDRESS;
    }
    return null; // No 'unknown' set
  }, [isConnectionInitialized, connectionStatus]);

  // Custom hooks
  const {
    gameData: updatedGameData,
    setGameData,
    fetchGameData,
    isLoading,
    setIsLoading,
  } = useFetchGameData(playerAddress, provider, addAlert, blackjackAddress);
  const { maxBet, totalEscrow } = updatedGameData;
  const [colorScheme, setNewColorScheme] = useColorScheme();

  // Initialize cardShown state
  const initializeCardShown = useCallback(
    () => ({
      player: (updatedGameData.playerHands || []).map((hand) =>
        (hand || []).map(() => false)
      ),
      dealer: (updatedGameData.dealerHand || []).map(() => false),
    }),
    [updatedGameData.playerHands, updatedGameData.dealerHand]
  );

  const [cardShown, setCardShown] = useState({
    player: [],
    dealer: [],
  });

  const [lastFlippedCardIndex, setLastFlippedCardIndex] = useState({});

  // Refs to track previous states
  const cardShownRef = useRef(cardShown);
  const lastFlippedCardIndexRef = useRef(lastFlippedCardIndex);
  const previousGameDataRef = useRef(null);

  // Update refs when state changes
  useEffect(() => {
    cardShownRef.current = cardShown;
  }, [cardShown]);

  useEffect(() => {
    lastFlippedCardIndexRef.current = lastFlippedCardIndex;
  }, [lastFlippedCardIndex]);

  // Animated hands state
  const [animatedPlayerHands, setAnimatedPlayerHands] = useState([]);
  const [animatedDealerHand, setAnimatedDealerHand] = useState([]);

  const { flipHandsSequentially } = useCardAnimation(
    updatedGameData,
    cardShown,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setLastFlippedCardIndex
  );

  // Synchronize betAmount with maxBet when betMaxEnabled is true
  useEffect(() => {
    if (betMaxEnabled) {
      setBetAmount(parseFloat(maxBet || "0"));
    }
  }, [betMaxEnabled, maxBet]);

  // **A. Initialize provider, signer, and connection status based on network**
  useEffect(() => {
    async function initializeConnection() {
      let updatedChainId = chainIdFromHook;

      if (!isConnected) {
        updatedChainId = 31337; // Default to local if not connected
      }

      setEffectiveChainId(updatedChainId);

      const isLocal = updatedChainId === 1337 || updatedChainId === 31337;

      if (isLocal) {
        // Set connection status to "local"
        setConnectionStatus("local");

        // Local test setup
        let address = playerAddress;
        if (
          !address ||
          !testAccounts.some(
            (acc) => acc.address.toLowerCase() === address.toLowerCase()
          )
        ) {
          const randAccount =
            testAccounts[Math.floor(Math.random() * testAccounts.length)];
          address = randAccount.address;
          setPlayerAddress(address);
          localStorage.setItem("playerAddress", address);
        }

        console.log("Player Address:", address);

        const rpcProvider = new ethers.JsonRpcProvider(
          process.env.REACT_APP_RPC_PROVIDER
        );

        // Normalize address comparison
        const account = testAccounts.find(
          (acc) => acc.address.toLowerCase() === address.toLowerCase()
        );

        console.log("Selected Account:", account);

        if (account && account.privateKey) {
          const walletSigner = new Wallet(account.privateKey, rpcProvider);
          setSigner(walletSigner);
          setProvider(rpcProvider);
        } else {
          setProvider(rpcProvider);
          setSigner(null);
        }
      } else if (isConnected && walletClient && chainIdFromHook === 80002) {
        // Set connection status to "amoy"
        setConnectionStatus("amoy");

        // Connected wallet setup
        setPlayerAddress(connectedAddress);
        const ethersProvider = new BrowserProvider(walletClient);
        try {
          const signerInstance = await ethersProvider.getSigner();
          setProvider(ethersProvider);
          setSigner(signerInstance);
        } catch (error) {
          console.error("Error getting signer from provider:", error);
          addAlert({
            status: "error",
            title: "Signer Initialization Failed",
            description: "Unable to get signer from wallet provider.",
          });
          setProvider(ethersProvider);
          setSigner(null);
        }
      } else {
        // Not connected to amoy, default to 'local'
        setConnectionStatus("local");

        // Fallback to a read-only provider
        const rpcProvider = new ethers.JsonRpcProvider(
          process.env.REACT_APP_RPC_PROVIDER
        );
        setProvider(rpcProvider);
        setSigner(null); // No signer available
      }

      // Mark connection as initialized
      setIsConnectionInitialized(true);
    }

    initializeConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chainIdFromHook,
    isConnected,
    connectedAddress,
    walletClient,
    playerAddress,
    addAlert,
  ]);

  // **B. Log connection status changes**
  useEffect(() => {
    if (connectionStatus) {
      console.log(`connected to ${connectionStatus}`);
    }
  }, [connectionStatus]);

  // **C. Fetch game data whenever playerAddress, provider, or blackjackAddress changes**
  useEffect(() => {
    if (playerAddress && provider && blackjackAddress) {
      fetchGameData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerAddress, provider, blackjackAddress, fetchGameData]);

  // **D. Update balances when gameData changes**
  const updateBalances = useCallback(async () => {
    if (!provider || !playerAddress || !updatedGameData || !blackjackAddress)
      return;

    const { totalEscrow } = updatedGameData;

    console.log("Updating balances...");
    console.log("Dealer Address:", blackjackAddress);
    console.log("Player Address:", playerAddress);
    console.log("Total Escrow:", totalEscrow);

    try {
      const [dealerBal, playerBal] = await Promise.all([
        provider.getBalance(blackjackAddress),
        provider.getBalance(playerAddress),
      ]);

      // Convert balances from BigInt to Ether units
      const dealerBalEther = ethers.formatEther(dealerBal);
      const playerBalEther = ethers.formatEther(playerBal);

      console.log("Dealer Balance (Ether):", dealerBalEther);
      console.log("Player Balance (Ether):", playerBalEther);

      // Convert balances and escrow to float for subtraction
      const dealerBalFloat = parseFloat(dealerBalEther);
      const totalEscrowFloat = parseFloat(totalEscrow || "0");

      console.log("Dealer Balance Float:", dealerBalFloat);
      console.log("Total Escrow Float:", totalEscrowFloat);

      // Ensure that totalEscrow is a valid number
      if (!isNaN(dealerBalFloat) && !isNaN(totalEscrowFloat)) {
        const adjustedDealerBal = (dealerBalFloat - totalEscrowFloat).toFixed(
          3
        );
        console.log("Adjusted Dealer Balance:", adjustedDealerBal);
        setDealerBankroll(adjustedDealerBal);
      } else {
        console.warn("Invalid dealerBalFloat or totalEscrowFloat");
      }

      const playerBalFormatted = parseFloat(playerBalEther).toFixed(3);
      console.log("Player Bankroll:", playerBalFormatted);
      setPlayerBankroll(playerBalFormatted);
    } catch (error) {
      console.error("Error updating balances:", error);
      addAlert({
        status: "error",
        title: "Balance Update Failed",
        description: "Unable to update balances at this time.",
      });
    }
  }, [provider, playerAddress, addAlert, updatedGameData, blackjackAddress]);

  useEffect(() => {
    if (updatedGameData && blackjackAddress) {
      updateBalances();
    }
  }, [updatedGameData, updateBalances, blackjackAddress]);

  // Function to add a placeholder card to a player's hand
  const addPlaceholderCardToHand = useCallback((handIndex) => {
    setAnimatedPlayerHands((prev) => {
      const updated = [...prev];
      updated[handIndex] = [...(updated[handIndex] || []), "??"];
      return updated;
    });
    setCardShown((prev) => {
      const updated = { ...prev };
      updated.player = [...(updated.player || [])];
      updated.player[handIndex] = [...(updated.player[handIndex] || []), false];
      return updated;
    });
    setLastFlippedCardIndex((prev) => ({
      ...prev,
      [handIndex]: (prev[handIndex] || 0) + 1,
    }));
  }, []);

  // Reset various UI states
  const resetShowStates = useCallback(() => {
    setShowGameStatus(false);
    setShowPayoutMessage(false);
    setShowLabel(false);
    setShowGameActions(false);
    setShowBetButton(false);
    console.log(
      "Resetting showGameStatus, showPayoutMessage, showLabel, showGameActions, showBetButton"
    );
    updateBalances();
  }, [updateBalances]);

  // Clear the game board
  const clearBoard = useCallback(() => {
    setConfettiRecycle(false);
    const initialPlayerHands = [[]];
    const initialDealerHand = [];
    setGameData({
      playerHands: initialPlayerHands,
      dealerHand: initialDealerHand,
      gameStarted: false,
      gameSettled: false,
      currentHandIndex: 0,
      betAmounts: [],
      handStatuses: [],
      payouts: [],
      handReasons: [],
    });
    setAnimatedPlayerHands(initialPlayerHands);
    setAnimatedDealerHand(initialDealerHand);
    setCardShown({
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    });
    cardShownRef.current = {
      player: initialPlayerHands.map((hand) => hand.map(() => false)),
      dealer: initialDealerHand.map(() => false),
    };
    setLastFlippedCardIndex({});
    resetShowStates();

    // Reset previousGameDataRef to allow initial deal for the next game
    previousGameDataRef.current = null;

    setNewColorScheme();
    updateBalances();
  }, [
    setGameData,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setNewColorScheme,
    updateBalances,
    resetShowStates,
  ]);

  // Define isCardInDesiredState at top level
  const isCardInDesiredState = useCallback(
    (handType, handIndex, cardIndex, faceDown) => {
      const desiredState = !faceDown;

      // Allow animation if the card is face-down ("??") and we expect it to be face-down
      const cardValue =
        handType === "player"
          ? cardShownRef.current.player?.[handIndex]?.[cardIndex]
          : cardShownRef.current.dealer?.[cardIndex];

      if (faceDown && cardValue !== "??") {
        // If the card is expected to be face-down but is not currently "??", we need animation
        return false;
      }

      if (!faceDown && cardValue === "??") {
        // If the card is supposed to be face-up but is currently "??", we need animation
        return false;
      }

      if (handType === "player") {
        return (
          cardShownRef.current.player &&
          cardShownRef.current.player[handIndex] &&
          cardShownRef.current.player[handIndex][cardIndex] === desiredState
        );
      } else if (handType === "dealer") {
        return (
          cardShownRef.current.dealer &&
          cardShownRef.current.dealer[cardIndex] === desiredState
        );
      }
      return false;
    },
    []
  );

  // Define animateAndRevealCard at top level
  const animateAndRevealCard = useCallback(
    async (handType, handIndex, cardIndex, cardValue, faceDown = false) => {
      // Check if the card is already in the desired state
      if (isCardInDesiredState(handType, handIndex, cardIndex, faceDown)) {
        console.log(
          `Card ${handType} ${
            handIndex !== null ? handIndex : ""
          } ${cardIndex} is already in desired state, skipping animation.`
        );
        return;
      }

      console.log(
        `Animating and revealing card: ${handType}, Hand: ${handIndex}, Card: ${cardIndex}, Value: ${cardValue}, FaceDown: ${faceDown}`
      );

      // Set placeholder (face-down card)
      if (handType === "player") {
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          if (!updated[handIndex]) updated[handIndex] = [];
          updated[handIndex][cardIndex] = "??"; // Placeholder for face-down card
          return updated;
        });
      } else if (handType === "dealer") {
        setAnimatedDealerHand((prev) => {
          const updated = [...prev];
          updated[cardIndex] = "??"; // Placeholder for face-down card
          return updated;
        });
      }

      await new Promise((resolve) => setTimeout(resolve, 350));

      // Reveal actual card only if it's face-up
      if (!faceDown) {
        if (handType === "player") {
          setAnimatedPlayerHands((prev) => {
            const updated = [...prev];
            updated[handIndex][cardIndex] = cardValue; // Reveal actual card
            return updated;
          });
        } else if (handType === "dealer") {
          setAnimatedDealerHand((prev) => {
            const updated = [...prev];
            updated[cardIndex] = cardValue; // Reveal actual card
            return updated;
          });
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 350));

      // Update cardShown state
      setCardShown((prev) => {
        const updated = { ...prev };
        if (handType === "player") {
          if (!updated.player) updated.player = [];
          if (!updated.player[handIndex]) updated.player[handIndex] = [];
          updated.player[handIndex][cardIndex] = !faceDown;
        } else if (handType === "dealer") {
          if (!updated.dealer) updated.dealer = [];
          updated.dealer[cardIndex] = !faceDown;
        }
        console.log(
          `Card shown updated: ${handType}, Hand: ${handIndex}, Card: ${cardIndex}, faceDown: ${faceDown}`
        );
        return updated;
      });

      await new Promise((resolve) => setTimeout(resolve, 350));
    },
    [isCardInDesiredState]
  );

  // Define handlePlayerHandUpdates at top level
  const handlePlayerHandUpdates = useCallback(async () => {
    const currentPlayerHands = updatedGameData.playerHands || [];
    const previousPlayerHands = previousGameDataRef.current?.playerHands || [];

    for (
      let handIndex = 0;
      handIndex < currentPlayerHands.length;
      handIndex++
    ) {
      const currentHand = currentPlayerHands[handIndex];
      const previousHand = previousPlayerHands[handIndex] || [];

      for (let cardIndex = 0; cardIndex < currentHand.length; cardIndex++) {
        const currentCard = currentHand[cardIndex];
        const previousCard = previousHand[cardIndex];

        if (currentCard && currentCard !== previousCard) {
          if (!isCardInDesiredState("player", handIndex, cardIndex, false)) {
            await animateAndRevealCard(
              "player",
              handIndex,
              cardIndex,
              currentCard
            );
          }
        }
      }
    }
  }, [updatedGameData.playerHands, animateAndRevealCard, isCardInDesiredState]);

  // Define detectSplit at top level
  const detectSplit = useCallback(() => {
    const previousHandCount =
      previousGameDataRef.current?.playerHands?.length || 1;
    const currentHandCount = updatedGameData.playerHands?.length || 1;
    return currentHandCount > previousHandCount;
  }, [updatedGameData.playerHands]);

  // Handle split-specific updates
  const handleSplit = useCallback(async () => {
    const previousHands = previousGameDataRef.current.playerHands || [];
    const newHands = updatedGameData.playerHands.slice(previousHands.length);

    for (let i = 0; i < newHands.length; i++) {
      const handIndex = previousHands.length + i;
      const newHand = newHands[i];
      const firstCard = newHand[0];
      const secondCard = newHand[1];

      // Initialize animatedPlayerHands for the new hand
      setAnimatedPlayerHands((prev) => {
        const updated = [...prev];
        updated[handIndex] = [firstCard, "??"]; // Placeholder for second card
        return updated;
      });

      // Initialize cardShown for the new hand
      setCardShown((prev) => ({
        ...prev,
        player: [
          ...(prev.player || []),
          [true, false], // First card is already shown, second is face down
        ],
      }));

      // Only animate and reveal the second card
      if (secondCard) {
        await animateAndRevealCard("player", handIndex, 1, secondCard, false);
      }
    }
  }, [updatedGameData.playerHands, animateAndRevealCard]);

  // **3. Modify handleBetAmountChange to disable Bet Max when user changes bet**
  const handleBetAmountChange = useCallback(
    (eOrValue) => {
      const value = eOrValue?.target?.value ?? eOrValue;

      // Allow empty string, '.', or '0' as valid intermediate inputs
      if (
        value === "" || // Allow empty input
        value === "." || // Allow just the decimal point
        /^(\d+(\.\d*)?|\.\d*)$/.test(value) // Allow numbers with optional decimal
      ) {
        setBetAmount(value);
        if (betMaxEnabled) {
          setBetMaxEnabled(false);
        }
      }
    },
    [betMaxEnabled]
  );

  // **4. Modify Betting component to pass betMaxEnabled and setBetMaxEnabled**
  const handleBetAmountChangeWithToggle = useCallback(
    (eOrValue) => {
      handleBetAmountChange(eOrValue);
    },
    [handleBetAmountChange]
  );

  // Define handleGameUpdate using useCallback
  const handleGameUpdate = useCallback(async () => {
    if (!updatedGameData) return;

    try {
      const previousGameData = previousGameDataRef.current;

      // Initialize animatedDealerCards here
      const animatedDealerCards = new Set();

      // Main game update logic
      if (updatedGameData.gameSettled) {
        console.log("Game is already settled. Handling end of game.");
        await handlePlayerHandUpdates();

        const dealerHand = updatedGameData.dealerHand || [];
        for (let i = 0; i < dealerHand.length; i++) {
          const cardValue = dealerHand[i];
          // Force the card to be revealed regardless of its current state
          await animateAndRevealCard("dealer", null, i, cardValue, false);
          animatedDealerCards.add(`${i}-${cardValue}`);
        }

        await flipHandsSequentially("dealer");
        setShowGameStatus(true);
        setShowPayoutMessage(true);
        await updateBalances();
        setShowBetButton(true);
      } else if (
        updatedGameData.gameStarted &&
        (!previousGameData || !previousGameData.gameStarted)
      ) {
        // New game has started
        setAnimatedPlayerHands([]);
        setAnimatedDealerHand([]);
        setCardShown(initializeCardShown());

        const initialDealOrder = [
          {
            handType: "player",
            handIndex: 0,
            cardIndex: 0,
            faceDown: false,
          },
          {
            handType: "dealer",
            handIndex: null,
            cardIndex: 0,
            faceDown: false,
          },
          {
            handType: "player",
            handIndex: 0,
            cardIndex: 1,
            faceDown: false,
          },
          {
            handType: "dealer",
            handIndex: null,
            cardIndex: 1,
            faceDown: true, // Dealer's second card is face-down
          },
        ];

        for (const deal of initialDealOrder) {
          const { handType, handIndex, cardIndex, faceDown } = deal;
          const cardValue =
            handType === "player"
              ? updatedGameData.playerHands?.[handIndex]?.[cardIndex]
              : updatedGameData.dealerHand?.[cardIndex];
          if (
            (cardValue &&
              !isCardInDesiredState(
                handType,
                handIndex,
                cardIndex,
                faceDown
              )) ||
            cardValue === "??"
          ) {
            await animateAndRevealCard(
              handType,
              handIndex,
              cardIndex,
              cardValue,
              faceDown
            );
            console.log("Initial deal card:", cardValue);
            if (handType === "dealer") {
              animatedDealerCards.add(`${cardIndex}-${cardValue}`);
            }
          }
        }

        const playerHands = updatedGameData.playerHands || [];

        for (let handIndex = 0; handIndex < playerHands.length; handIndex++) {
          const playerHand = playerHands[handIndex] || [];

          // Determine starting card index based on hand
          const startCardIndex = handIndex === 0 ? 2 : 0;

          for (
            let cardIndex = startCardIndex;
            cardIndex < playerHand.length;
            cardIndex++
          ) {
            const cardValue = playerHand[cardIndex];

            if (
              cardValue &&
              !isCardInDesiredState("player", handIndex, cardIndex, false)
            ) {
              await animateAndRevealCard(
                "player",
                handIndex,
                cardIndex,
                cardValue,
                false
              );
            }
          }

          // Ensure the first card of additional hands is visible
          if (handIndex > 0 && playerHand.length > 0) {
            const firstCard = playerHand[0];
            if (
              firstCard &&
              !isCardInDesiredState("player", handIndex, 0, false)
            ) {
              // Set as shown without animation
              setAnimatedPlayerHands((prev) => {
                const updated = [...prev];
                if (!updated[handIndex]) updated[handIndex] = [];
                updated[handIndex][0] = firstCard; // Reveal actual card
                return updated;
              });

              setCardShown((prev) => {
                const updated = { ...prev };
                if (!updated.player) updated.player = [];
                if (!updated.player[handIndex]) updated.player[handIndex] = [];
                updated.player[handIndex][0] = true;
                return updated;
              });
            }
          }
        }

        console.log("Initial deal complete. Allowing player actions.");
        setShowGameActions(true);
        setShowBetButton(false);
      } else if (detectSplit()) {
        console.log("Split detected. Handling split.");
        await handleSplit();
      } else if (previousGameData) {
        console.log("Handling other game data updates.");

        await handlePlayerHandUpdates();

        const currentDealerHand = updatedGameData.dealerHand || [];
        const previousDealerHand = previousGameData.dealerHand || [];

        for (let i = 0; i < currentDealerHand.length; i++) {
          const currentCard = currentDealerHand[i];
          const previousCard = previousDealerHand[i];

          if (
            currentCard &&
            currentCard !== previousCard &&
            !animatedDealerCards.has(`${i}-${currentCard}`)
          ) {
            if (!isCardInDesiredState("dealer", null, i, false)) {
              await animateAndRevealCard("dealer", null, i, currentCard, false);
              animatedDealerCards.add(`${i}-${currentCard}`);
            }
          }
        }

        if (updatedGameData.gameStarted && !updatedGameData.gameSettled) {
          setShowGameActions(true);
          setShowBetButton(false);
        }
      }

      // Update previousGameDataRef after handling
      previousGameDataRef.current = JSON.parse(JSON.stringify(updatedGameData));
    } catch (error) {
      console.error("Error during game update:", error);
      addAlert({
        status: "error",
        title: "Game Update Error",
        description: "An error occurred while updating the game state.",
      });
    } finally {
      isUpdatingRef.current = false;
      if (needsUpdateRef.current) {
        needsUpdateRef.current = false;
        isUpdatingRef.current = true;
        await handleGameUpdate();
      }
    }
  }, [
    updatedGameData,
    addAlert,
    flipHandsSequentially,
    initializeCardShown,
    updateBalances,
    handlePlayerHandUpdates,
    animateAndRevealCard,
    isCardInDesiredState,
    detectSplit,
    handleSplit,
  ]);

  // useEffect to trigger handleGameUpdate when gameData changes
  useEffect(() => {
    if (isUpdatingRef.current) {
      needsUpdateRef.current = true;
    } else {
      isUpdatingRef.current = true;
      handleGameUpdate();
    }
  }, [handleGameUpdate]);

  // Handle confetti for Blackjack
  useEffect(() => {
    if (
      updatedGameData.handStatuses?.includes("BLACKJACK!") &&
      !confettiRecycle
    ) {
      setConfettiRunning(true);
      setConfettiRecycle(true);
    }
  }, [updatedGameData.handStatuses, confettiRecycle]);

  // Start a new game
  const startNewGame = useCallback(async () => {
    if (!signer) {
      addAlert({
        status: "error",
        title: "Signer not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }
    if (!playerAddress) {
      addAlert({
        status: "error",
        title: "Player address not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }

    setIsLoading(true);
    setLoadingAction("Placing Bet");
    if (parseFloat(betAmount) <= 0 || isNaN(betAmount)) {
      addAlert({
        status: "error",
        title: "Invalid Bet",
        description: "Please enter a valid bet amount greater than 0.",
      });
      setLoadingAction("");
      setIsLoading(false);
      return;
    }

    if (parseFloat(betAmount) > parseFloat(maxBet)) {
      addAlert({
        status: "error",
        title: "Bet Exceeds Maximum",
        description: `The maximum allowed bet is ${maxBet}.`,
      });
      setLoadingAction("");
      setIsLoading(false);
      return;
    }

    localStorage.setItem("betAmount", betAmount.toString());
    clearBoard();
    try {
      await executeContractFunction({
        signer,
        blackjackAddress,
        playerAddress,
        setIsLoading,
        fetchGameData,
        addAlert,
        updateBalances,
        functionName: "startGame",
        functionArgs: [],
        value: ethers.parseEther(betAmount.toString()),
        setLoadingAction,
        isLocal: effectiveChainId === 31337 || effectiveChainId === 1337,
      });
    } catch (error) {
      console.error("Error starting new game:", error);
      addAlert({
        status: "error",
        title: "Transaction Failed",
        description:
          error.message || "An error occurred while starting a new game.",
      });
    } finally {
      setLoadingAction("");
      setIsLoading(false);
      updateBalances();
    }
  }, [
    signer,
    playerAddress,
    betAmount,
    addAlert,
    fetchGameData,
    updateBalances,
    setIsLoading,
    setLoadingAction,
    clearBoard,
    effectiveChainId,
    maxBet,
    blackjackAddress,
  ]);

  // Handle player actions (hit, stand, etc.)
  const handleAction = useCallback(
    (actionFunction) => async () => {
      if (!signer || !playerAddress) {
        addAlert({
          status: "error",
          title: "Signer Not Available",
          description: "Signer is not available, try reconnecting.",
        });
        return;
      }

      resetShowStates();
      const actionMap = {
        hit: "Hitting",
        stand: "Standing",
        doubleDown: "Doubling Down",
        split: "Splitting",
      };
      setLoadingAction(actionMap[actionFunction] || "Loading");
      console.log(`Handling action: ${actionFunction}`);

      if (actionFunction === "hit" || actionFunction === "doubleDown") {
        addPlaceholderCardToHand(updatedGameData.currentHandIndex);
      }

      if (actionFunction === "split") {
        const [card1, card2] =
          updatedGameData.playerHands[updatedGameData.currentHandIndex] || [];
        setGameData((prev) => {
          const updated = { ...prev };
          updated.playerHands = [...(prev.playerHands || [])];
          updated.playerHands[updatedGameData.currentHandIndex] = [card1, "??"];
          updated.playerHands.splice(updatedGameData.currentHandIndex + 1, 0, [
            card2,
            "??",
          ]);
          return updated;
        });
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          updated[updatedGameData.currentHandIndex] = [card1, "??"];
          updated.splice(updatedGameData.currentHandIndex + 1, 0, [
            card2,
            "??",
          ]);
          return updated;
        });
        setCardShown((prev) => {
          const updated = { ...prev };
          updated.player = [...(updated.player || [])];
          updated.player[updatedGameData.currentHandIndex] = [true, false];
          updated.player.splice(updatedGameData.currentHandIndex + 1, 0, [
            true,
            false,
          ]);
          return updated;
        });
        setLastFlippedCardIndex((prev) => ({
          ...prev,
          [updatedGameData.currentHandIndex]: 0,
          [updatedGameData.currentHandIndex + 1]: 0,
        }));
      }

      let value = null;
      if (actionFunction === "doubleDown" || actionFunction === "split") {
        const currentBet =
          updatedGameData.betAmounts?.[updatedGameData.currentHandIndex] || "0";
        value = ethers.parseEther(currentBet);
      }

      // Pass all required parameters correctly
      await executeContractFunction({
        signer,
        blackjackAddress,
        playerAddress,
        setIsLoading,
        fetchGameData,
        addAlert,
        updateBalances,
        functionName: actionFunction,
        functionArgs: [],
        value,
        setLoadingAction,
        isLocal: effectiveChainId === 31337 || effectiveChainId === 1337,
      });

      await fetchGameData();

      if (["hit", "doubleDown", "split"].includes(actionFunction)) {
        setCardShown((prev) => ({
          ...prev,
          player: (updatedGameData.playerHands || []).map((hand, hIdx) =>
            (hand || []).map((card, cIdx) =>
              prev.player[hIdx] && prev.player[hIdx][cIdx] !== undefined
                ? prev.player[hIdx][cIdx]
                : false
            )
          ),
        }));
        await flipHandsSequentially("player");
      }

      setLoadingAction("");
      updateBalances();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      signer,
      playerAddress,
      addAlert,
      resetShowStates,
      addPlaceholderCardToHand,
      fetchGameData,
      flipHandsSequentially,
      effectiveChainId,
      updateBalances,
      setLoadingAction,
      blackjackAddress,
      setGameData,
      updatedGameData,
    ]
  );

  // Calculate current hand details
  const currentHand =
    updatedGameData.playerHands?.[updatedGameData.currentHandIndex] || [];
  const possibleHandValues = calculateHandValues(currentHand);
  const getCardValue = (card) =>
    card ? (card.length === 3 ? card.slice(0, 2) : card.slice(0, 1)) : "0";
  const canDoubleDown =
    currentHand.length === 2 &&
    possibleHandValues.some((val) => [9, 10, 11].includes(val)) &&
    !updatedGameData.gameSettled;
  const canSplit =
    currentHand.length === 2 &&
    getCardValue(currentHand[0]) === getCardValue(currentHand[1]);

  // Handle account change
  const handleAccountChange = useCallback(
    (newAddress) => {
      clearBoard();
      setPlayerAddress(newAddress);
      localStorage.setItem("playerAddress", newAddress);
      updateBalances();
    },
    [clearBoard, updateBalances]
  );

  return (
    <Box w="100%" h="100%" p={fullscreen ? 0 : 3}>
      <Box
        w="100%"
        minH={fullscreen ? "100dvh" : "auto"}
        bg="radial-gradient(circle, #35654d 0%, #2f5a46 40%, #254c3a 100%)"
        borderColor="#1d3a2e"
        borderRadius={fullscreen ? "0" : "10px"}
        borderWidth={fullscreen ? 0 : 3}
        py={fullscreen ? 5 : 0}
        position="relative"
        align="center"
        justify="center"
        boxShadow="inset 0 0 15px rgba(0, 0, 0, 0.3), inset 0 0 3px rgba(255, 255, 255, 0.2)"
        alignContent="center"
        overflow="visible"
      >
        <IconButton
          icon={fullscreen ? <FaCompress /> : <FaExpand />}
          onClick={toggleFullscreen}
          aria-label="Toggle Fullscreen"
          colorScheme="whiteAlpha"
          size="lg"
          position="absolute"
          right={0}
          top={isStandalone && fullscreen ? 8 : 0}
          _hover={{ transform: "scale(1.05)" }}
          cursor="pointer"
          zIndex={1500}
          variant="ghost"
        />
        {confettiRunning && (
          <Confetti
            run={confettiRunning}
            recycle={confettiRecycle}
            tweenDuration={10000}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
            }}
          />
        )}
        <Box overflowX="auto">
          <Box>
            <VStack>
              {!updatedGameData.gameStarted && !isLoading ? (
                <Box h="530px">
                  <WelcomeScreen
                    ethAmount={dealerBankroll}
                    escrowAmount={totalEscrow}
                  />
                </Box>
              ) : (
                <Box h="540px">
                  <Box h="210px">
                    <DealerHand
                      animatedDealerHand={animatedDealerHand}
                      colorScheme={colorScheme}
                      gameStarted={updatedGameData.gameStarted}
                      ethAmount={dealerBankroll}
                      escrowAmount={totalEscrow}
                    />
                  </Box>
                  <Box h="320px">
                    <PlayerHands
                      animatedPlayerHands={animatedPlayerHands}
                      colorScheme={colorScheme}
                      gameData={updatedGameData}
                      showLabel={showLabel}
                      showGameActions={showGameActions}
                      isLoading={isLoading}
                      handleAction={handleAction}
                      canDoubleDown={canDoubleDown}
                      canSplit={canSplit}
                      loadingAction={loadingAction}
                      showGameStatus={showGameStatus}
                      showPayoutMessage={showPayoutMessage}
                    />
                  </Box>
                </Box>
              )}
              <Box h="70px">
                {(!updatedGameData.gameStarted || showBetButton) && (
                  <Betting
                    isLoading={isLoading}
                    betAmount={betAmount}
                    handleBetAmountChange={handleBetAmountChangeWithToggle}
                    startNewGame={startNewGame}
                    loadingAction={loadingAction}
                    dealerBankroll={dealerBankroll}
                    maxBet={maxBet}
                    playerBalance={playerBankroll}
                    betMaxEnabled={betMaxEnabled}
                    setBetMaxEnabled={setBetMaxEnabled}
                    chainId={chainIdFromHook}
                  />
                )}
              </Box>
              <Box h="65px">
                <PlayerAccount
                  currentAccount={playerAddress}
                  onAccountChange={handleAccountChange}
                  playerBalance={playerBankroll}
                  isDisabled={
                    isLoading || (updatedGameData.gameStarted && !showBetButton)
                  }
                />
              </Box>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Blackjack;
